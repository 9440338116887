.user-profile-image {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin: 0 5px 5px 0;
    line-height: 2rem;
    border-radius: 2rem;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    background: #123456;
}
